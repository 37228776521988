<template>
  <div class="card p-0">
    <div class="card-body p-0">
      <div class="table-responsive">
        <table class="table table-striped gy-3 gs-2 gx-1 text-nowrap" style="" v-if="this.data.length > 0">
          <thead>
            <tr class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200">
              <th class="">#</th>
              <th class="">Name</th>
              <th class="">Buyin</th>
              <th class="">+ / -</th>
              <th class=""></th>
              <th>
                <span @click="clickNet()"
                  :class="TotalOrNet == 'total' ? 'text-primary text-decoration-underline' : ''">Net</span> /
                <span @click="clickTotal()"
                  :class="TotalOrNet == 'net' ? 'text-primary text-decoration-underline' : ''">Total</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data" :key="item" class="align-middle">
              <td>{{ index + 1 }}</td>
              <td>{{ item.name }} <i class="bi bi-trash ms-1" @click="clickDelete(item.name, item.id)"></i></td>
              <td>{{ item.buyin }}</td>
              <td>
                <input type="number" class="form-control" v-model="item.amount" style="width:68px;" 
                  onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 45'
                  @focus="$event.target.select()" />
              </td>
              <td>
                <button @click="amountAdd(index)" class="btn btn-sm btn-primary px-3" style="">
                  <i class="bi bi-plus-slash-minus"></i>
                </button>
              </td>
              <td>
                <input type="number" class="form-control" style="width:68px; float:left" v-model="item.total"
                  onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 45'
                  @focus="$event.target.select()" @input="saveData()" />
              </td>
            </tr>
            <tr class="fw-bolder" v-if="this.data">
              <td>Total</td>
              <td></td>
              <td>${{ buyinTotal }}</td>
              <td></td>
              <td colspan="2" class="text-end pe-5">
                <span v-if="Math.sign(endTotal) == 1" class="text-success"> Extra ${{ endTotal }}</span>
                <span v-if="Math.sign(endTotal) == -1" class="text-danger"> Missing ${{ endTotal }}</span>
                <span v-if="Math.sign(endTotal) == 0" class="text-primary"> Correct ${{ endTotal }} <i
                    class="fa fa-check text-primary"></i></span>

              </td>
            </tr>
          </tbody>
        </table>
        <div class="separator my-3"></div>
        <table class="table table-striped gy-0 gs-5" style="">
          <tr style="vertical-align:middle;">
            <td class="">Add Player</td>
            <td>
              <input type="text" class="form-control me-3" v-model="addName" style="width:150px;"
                @focus="$event.target.select()" />
            </td>
            <td>
              <button @click="addPlayer(this.addName)" class="btn btn-sm btn-primary">
                +
              </button>
            </td>
          </tr>
        </table>
        <div class="separator my-3"></div>
        <div class="m-5">
          <button @click="showRemove()" class="btn btn-sm btn-danger" v-if="toggleRemove == true">
            Remove All Data
          </button>
        </div>
        <div class="m-5" v-if="toggleRemove == false">
          <button @click="removeAllData()" class="btn btn-sm btn-success me-3">
            Confirm Remove
          </button>
          <button @click="hideRemove()" class="btn btn-sm btn-danger">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="card p-0 mt-5">
    <div class="card-body p-5">
      <div class="table-responsive" id="copyText">
        <div v-for="item, index in data" :key="item">
          {{ index + 1 }}. {{ item.name }} {{ item.buyin }}
          <span v-if="item.total || item.total === 0">
            ({{ item.total }})
            <span v-if="TotalOrNet == 'total'">
              {{ (item.total - item.buyin) > 0 ? "+" : "" }}{{ (item.total - item.buyin) }}
            </span>
          </span>
        </div>
        Total: ${{ this.buyinTotal }}
        <span v-if="Math.sign(endTotal) == 1"> (Extra ${{ endTotal }})</span>
        <span v-if="Math.sign(endTotal) == -1"> (Missing ${{ endTotal }})</span>
        <span v-if="Math.sign(endTotal) == 0"> (Correct ${{ endTotal }})</span>
      </div>
      <div>
        <button @click="copyText()" class="btn btn-sm btn-primary mt-3">
          Copy Text
        </button>
      </div>
    </div>
  </div>

  <div class="card p-0 mt-5">
    <div class="card-body p-0">
      <div class="table-responsive">
        <table class="table table-striped gy-3 gs-2 gx-1 text-nowrap" style="">
          <thead>
            <tr class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200">
              <th class="">#</th>
              <th class="">Name</th>
              <th class="">Buyin</th>
              <th class="">+ / -</th>
              <th class="">Time</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in record" :key="item" class="align-middle">
              <td>{{ index + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.buyin }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.time }}</td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
  <div class="google-maps mt-10" v-if="chkDev()">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d59052.23223418827!2d114.16258!3d22.324744!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403e2eda332980f%3A0xf08ab3badbeac97c!2z6aaZ5riv!5e0!3m2!1szh-TW!2sus!4v1661328425524!5m2!1szh-TW!2sus"
      width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
</template>

<style>
.google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}

.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
</style>

<script>
import moment from "moment";
import { ElMessage } from "element-plus";
export default {
  name: "bookkeepPage",
  data() {
    return {
      data: [],
      record: [],
      addName: "",
      toggleRemove: true,
      TotalOrNet: "net"
    };
  },
  computed: {
    buyinTotal() {
      var total = 0
      this.data.forEach(element => {
        total += Number(element.buyin)
      });
      return Number(total)
    },
    endTotal() {
      var endTotal = 0
      var buyTotal = 0
      this.data.forEach(item => {
        endTotal += Number(item.total)
        buyTotal += Number(item.buyin)
      });

      if (this.TotalOrNet == 'total') {
        endTotal = (endTotal - buyTotal) * -1
      } else if (this.TotalOrNet == 'net') {
        endTotal = endTotal * -1
      }
      return Number(endTotal)
    }
  },
  methods: {
    chkDev() {
      if (process.env.NODE_ENV === 'development') {
        return true
      } else {
        return false
      }
    },
    clickDelete(name, id) {
      if (confirm("Confirm to delete '" + name + "'?") == true) {
        this.record.push({ id: this.record.length, name: name, buyin: this.data[id].buyin, amount: "deleted", time: moment().format("h:mmA D/M/YY") })
        this.data.splice(id, 1);
        console.log(this.data)

      }
    },
    async copyText() {


      try {
        var outputText = ''
        var endAmount = ''

        if (Math.sign(this.endTotal) == 1) { endAmount = ' (Extra $' + this.endTotal + ')' }
        else if (Math.sign(this.endTotal) == -1) { endAmount = ' (Missing $' + this.endTotal + ')' }
        else if (Math.sign(this.endTotal) == 0) { endAmount = ' (Correct $' + this.endTotal + ')' }
        this.data.forEach((item, index) => {
          item.total = Number(item.total)
          item.buyin = Number(item.buyin)
          var copyTotal = ''
          if (item.total || item.total === 0) {
            copyTotal = '(' + item.total + ') '
            if (this.TotalOrNet == 'total') {
              if ((item.total - item.buyin) > 0) {
                copyTotal += "+"
              }
              copyTotal += (item.total - item.buyin)
            }
          }
          outputText += index + 1 + '. ' + item.name + ' ' + item.buyin + ' ' + copyTotal + '\n'
        });
        outputText += 'Total: $' + this.buyinTotal + endAmount
        await navigator.clipboard.writeText(outputText);
        ElMessage.success('Copied');
      } catch ($e) {
        alert('Cannot copy');
      }

    },
    clickTotal() {
      this.TotalOrNet = 'total'
      this.saveData()
    },
    clickNet() {
      this.TotalOrNet = 'net'
      this.saveData()
    },
    amountAdd(index) {
      this.data[index].buyin += Number(this.data[index].amount)
      this.record.push({ id: this.record.length, name: this.data[index].name, buyin: this.data[index].buyin, amount: this.data[index].amount, time: moment().format("h:mmA D/M/YY") })
      this.saveData()
    },
    addPlayer(name) {
      var dupName = false;
      this.data.forEach(item => {
        if (item.name == name) {

          dupName = true
        }
      });

      if (!name) {
        ElMessage.error(this.$t("msgEmptyName"));
      } else if (dupName) {
        ElMessage.error("Duplicated name");
      } else {
        this.data.push({ id: this.data.length, name: name, buyin: 0, amount: 0, total: '' })
        this.addName = ''
        this.saveData()
      }
    },
    removeAllData() {
      this.data = []
      this.record = []
      localStorage.removeItem('data');
      localStorage.removeItem('record');
      this.toggleRemove = true
    },
    showRemove() {
      this.toggleRemove = false
    },
    hideRemove() {
      this.toggleRemove = true
    },
    saveData() {
      localStorage.setItem('data', JSON.stringify(this.data))
    },
    saveRecord() {
      localStorage.setItem('record', JSON.stringify(this.record))
    }
  },
  watch: {
    data() {
      //localStorage.data = this.data
      //console.log(localStorage.data)
    }
  },
  mounted() {
    this.data = JSON.parse(localStorage.getItem('data'))
    this.record = JSON.parse(localStorage.getItem('record'))
    if (!this.data) {
      this.data = []
    }
    if (!this.record) {
      this.record = []
    }
  }
};
</script>
